import React from 'react';

const HomePage = () => {
	return (
			<div>
				<p className="font-bold lg:text-6xl md:text-5xl sm:text-4xl text-3xl text-white font-medium tracking-widest">
					COMING SOON
				</p>
			</div>
	);
}

export default HomePage;
